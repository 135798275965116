import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import { DoctorReportQueryQuery, DoctorReportQueryQueryVariables } from '@global/utils/remote-graphql-types';

export const DoctorReportQuery: TypedDocumentNode<DoctorReportQueryQuery, DoctorReportQueryQueryVariables> = gql`
  query DoctorReportQuery($id: String!) {
    sleep_restriction(where: { user_id: { _eq: $id } }, order_by: { start_date: asc }) {
      before_eficiency
      before_fill_count
      before_time_in_bed
      before_time_sleep
      desired_wake_up
      hour_to_sleep
      new_time_in_bed
      start_date
      user_id
    }

    sleep_diary(where: { user_id: { _eq: $id } }, order_by: { date: asc }) {
      id
      tags {
        sleep_tag
      }
      autogenic_training
      behavior_activation
      comment
      concentration
      date
      deep_breath
      energy
      get_up
      go_bed
      go_sleep
      grade
      humor
      id
      medicine
      imagery
      meditation
      paradoxical_intention
      gratitude
      light_therapy
      challenge_catastrophic_thinking
      parking_lot
      pmr
      relationships
      stimulus_control
      thought_block
      time_to_sleep
      timezone
      wake_up
      wake_up_count
      wake_up_duration
      created_at
      snore_count
      snore_duration
      sleep_diary_medicines {
        name
        dose
        id
      }
    }

    rating(where: { user: { _eq: $id } }, order_by: { created: desc }, limit: 1) {
      created
    }

    user(where: { id: { _eq: $id } }) {
      id
      day_goal
      night_goal
      last_name
      first_name
      created_at
      gender
      current_journey_id
      tried_alcohol
      tried_medicine
      tried_melatonin
      tried_other
      tried_restriction
      tried_sleep_hygiene
      phone
      email
      user_tags {
        tag
      }
      toolbox {
        id
        autogenic_training
        behavior_activation
        deep_breath
        imagery
        meditation
        paradoxical_intention
        parking_lot
        gratitude
        light_therapy
        challenge_catastrophic_thinking
        sleep_restriction
        pmr
        stimulus_control
        thought_block
      }
      gad {
        q1
        q2
        created_at
      }
      gad7s {
        created_at
        q1
        q2
        q3
        q4
        q5
        q6
        q7
      }
      phq9s {
        created_at
        q1
        q2
        q3
        q4
        q5
        q6
        q7
        q8
        q9
      }
      phq {
        created_at
        q1
        q2
      }
      isi {
        id
        q1
        age
        created_at
        q2
        q4
        q3
        q5
        q6
        q7
      }
      kuppermans(where: { user_id: { _eq: $id } }) {
        id
        created_at
        q1
        q2
        q3
        q4
        q5
        q6
        q7
        q8
        q9
        q10
        q11
        q12
        q13
      }
      profile_picture
      psqi {
        id
        created_at
        q1
        age
        q2
        q3
        q4
        q5a
        q5b
        q5c
        q5d
        q5e
        q5f
        q5g
        q5h
        q5i
        q5j
        q6
        q7
        q8
        q9
      }
      user_journeys {
        journey_id
      }
      user_program_sessions(order_by: { created_at: desc }) {
        session_id
        state
        created_at
      }
    }
  }
`;
